input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

.stepper {
  border: 1px solid #ddd;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.stepper-header {
  padding: 10px;
  text-align: center;
}

.stepper-content {
  padding: 20px;
}

.stepper-section {
  display: none;
}

.stepper-section.active {
  display: block;
}

.stepper-section h3 {
  margin-top: 0;
}

.form-button {
  border-radius: 25px;
  position: relative;

  &:hover {
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
    transition: all .5s ease-in-out;
    transform: scale(1.05);
  }
}


.form-radio {
  appearance: none;
  display: none;
}

.details {
  border: 2px solid gray;
  border-radius: 25px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: border-color 0.2s ease-out;
}

.form-button:hover .details {
  border-color: darkgray;
}

.form-radio:checked~.details {
  border-color: #3b82f6;
  box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.15);
}

.form-radio:disabled~.details {
  color: darkgray;
  cursor: default;
}

.form-radio:disabled~.details {
  color: gray;
}

.form-button:hover .form-radio:disabled~.details {
  border-color: var(--color-gray);
  box-shadow: none;
}

.form-button:hover .form-radio:disabled {
  border-color: var(--color-gray);
}

label {
  display: block;
  margin-bottom: 10px;
}

input[type="radio"] {
  margin: 0 10px 0 0;
}

button {
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #555;
}

.prev-btn {
  float: left;
}

.next-btn {
  float: right;
}